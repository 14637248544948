<template>
<div class="c-InlineList">
  <ul class="c-InlineList__items u-FlexBox u-FlexBox--middle">
    <li class="c-InlineList__item"><span>公開状態：有効</span></li>
    <li class="c-InlineList__item"><span>期間：2021/10/10 - 2021/11/10</span><i>期間超過</i></li>
    <li class="c-InlineList__item"><span>利用状況（当日利用額 / 日予算）：5,000 / 9,999</span><i>予算超過</i></li>
    <li class="c-InlineList__item"><span>全期間利用額：9,999</span></li>
  </ul><!-- /c-InlineList__items  -->
</div><!-- /c-InlineList  -->
</template>
